<template lang="pug">
  layout-access
    main#main.home.d-flex.flex-grow-1.justify-content-center
      b-container(fluid)
        b-row
          b-col.mx-auto(sm='12' md='10' lg='8' xl='6')
            section.l-section
              .l-section-h
                b-card.card-transparent
                  .pb-5.text-center
                    .wc-i.wc-symbol.rounded(style='width: 80px; height: 80px')
                    .h4.m-0.mt-2.text-uppercase.text-white
                      | {{ $wc.conf.base.name }}

                  .d-flex.justify-content-center
                    wc-loaders-cube
</template>

<script>
import LayoutAccess from '@components/layouts/Access'
import WcLoadersCube from '@components/shared/loaders/WcLoadersCube'

export default {
  name: 'home',
  components: {
    LayoutAccess,
    WcLoadersCube,
  },
  mounted() {
    window.location.replace(process.env.VUE_APP_WC_AUTH_DEFAULT_SERVICE_URL)
  },
}
</script>
